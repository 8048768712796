import React from 'react';
import { ReactComponent as LogoType } from '../../images/Logo-row-white.svg'
import QRCode from 'qrcode.react';
import VCardsJS from 'vcards-js';
import { Redirect } from 'react-router-dom';


// Set vCard fields
function vcardString(body) {
    let vCard = VCardsJS();
    vCard.firstName = body.firstName;
    vCard.lastName = body.lastName;
    vCard.organization = "Apple Inc.";
    vCard.title = body.position;
    vCard.workUrl = "www.apple.com";
    vCard.cellPhone = body.phone;
    vCard.workPhone = body.workPhone;
    vCard.homePhone = body.homePhone;
    vCard.workAddress.street = body.address;
    vCard.workAddress.city = body.city;
    vCard.workAddress.postalCode = body.postcode;
    vCard.workAddress.countryRegion = body.country;
    vCard.workEmail = body.email;
    return vCard.getFormattedString();
}

function CardContent(props) {
    return  <div className="mx-3">
        {   // If no vCard, redirect to main page
            !props.vCard.lastName && 
            <Redirect to="/" push={true} />
        }

        <div className="row">
            <div className="col-12 my-3">
                <LogoType />
            </div>
        </div>
        <div className="row my-3">
            <div className="col-12 text-dark">
                <div className="h4 font-weight-normal text-dark mb-1">{`${props.vCard.firstName} ${props.vCard.lastName}`}</div>
                <div className="h4 font-weight-normal text-dark">Apple Inc.</div>
            </div>
        </div>
        <div className="row my-3">
            <div className="col-12 text-white">
                <div className="font-weight-normal text-dark mb-1">{props.vCard.position}</div>
                <div className="font-weight-normal text-dark">{props.vCard.email}</div>
            </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
            <div className="pt-1 px-1 pb-0 border bg-white rounded">
                <QRCode value={vcardString(props.vCard)} />
            </div>
        </div>
    </div>;
  
}

export default CardContent;
