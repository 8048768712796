import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardContent from './CardContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faImages } from '@fortawesome/pro-light-svg-icons'
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { detect } from 'detect-browser';
import ReactGA from 'react-ga';


const browser = detect();
const isApple = (browser && ((browser.os === 'Mac OS') || (browser.os === 'iOS')));

const b64toBlob = async (b64Data, contentType = 'application/vnd.apple.pkpass') => {
    const url = `data:${contentType};base64,${b64Data}`;
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
};

async function createWallet(body) {
    const blob = await b64toBlob(body.walletData.data);
    saveAs(blob, 'veecard.pkpass');
}

async function createImage() {
    const element = document.querySelector('#preview');
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');
    saveAs(data, 'veecard.png');
}

function CardPreview(props) {
    // Google analytics
    ReactGA.pageview('/cardpreview')

    const { t, i18n } = useTranslation();
    const [creating, setCreating] = useState(false);
    const emailSent = props.vCard.walletData && props.vCard.walletData.email;

    const onWalletClick = async (evt) => {
        setCreating(true);
        try {
            await createWallet(props.vCard)
        } finally {
            setCreating(false);
        }
    }

    const onDownloadImageClick = async (evt) => {
        setCreating(true);
        try {
            await createImage(props.vCard)
        } finally {
            setCreating(false);
        }
    } 

    return <div className="container vh-100 d-flex justify-content-center flex-column">
        <div className="mb-auto mt-2">
            <div className="row">
                <div className="col-12">
                    <Link to="/" className="text-dark" >
                        <FontAwesomeIcon icon={faArrowLeft} /><span className="ml-1">{t('common.back')}</span>
                    </Link>
                </div>
            </div>
        </div>
        <div className="mb-2 align-self-center">
            {emailSent ? <h5 className="text-center">{t('preview.checkyouremails', { email: props.vCard.email })}</h5> : '' }
        </div>
        <div className="mb-auto align-self-center">
            <div className="preview bg-white" id="preview">
                <CardContent {...props} />
            </div>
            <div className="d-flex flex-row justify-content-around">
                {emailSent ? '' : (
                    isApple ? (
                        <button style={{ backgroundRepeat: 'no-repeat', backgroundPosition: 'center',
                            backgroundImage: 'url(/apple_wallet_badges/' + i18n.languages[0] + '.svg)', width: 120, height: 40, opacity: creating?0.5:1}}
                            className="my-2 bg-transparent border-0 mr-1" alt="Wallet badge" onClick={onWalletClick} disabled={creating} />
                    ) : (
                        <button className="btn btn-primary mb-2 mx-1 p-0 text-white"
                            style={{width: 110, height: 34, marginTop: 11, backgroundColor: '#000', borderRadius: 6}}
                            disabled={creating} onClick={onDownloadImageClick}>
                            <div className="d-flex flex-row justify-content-start">
                                <div className="my-auto mx-2"><FontAwesomeIcon icon={faImages} size="lg" /></div>
                                <div className="d-flex flex-column align-items-start my-auto">
                                    <small style={{fontSize: 9, lineHeight: '1em'}}>{t('preview.saveto')}</small>
                                    <div style={{fontSize: 11, lineHeight: '1em'}}>{t('preview.savetoimage')}</div>
                                </div>                        
                            </div>
                        </button>
                    )
                )}
            </div>
        </div>
    </div>;

}

export default CardPreview;
